import { withRtlSupport } from '@skyscanner/backpack-web/bpk-component-icon';
import BpkAirlineIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airline';
import BpkAirportsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airports';
import BpkAlertActiveIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/alert--active';
import BpkCalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import BpkCamperVanIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/camper-van';
import BpkCarsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cars';
import BpkFlexibleIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cars-flexible';
import BpkCleaningMedicalIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/cleaning-medical';
import BpkElectricIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/electric';
import BpkFilterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/filter';
import BpkFlightIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import BpkFlightFlexibleIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight-flexible';
import BpkFlightLandingIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight-landing';
import BpkInformationIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/information';
import BpkInsuranceIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/insurance';
import BpkLocationIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/location';
import BpkMobileIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/mobile';
import BpkMoneyIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/money';
import BpkPetrolIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/petrol';
import BpkRefreshIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/refresh';
import BpkRoomIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/room';
import BpkSearchIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/search';
import BpkSelfServiceIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/self-service';
import BpkStopsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/stops';
import BpkTickCircleIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/tick-circle';
import BpkTimeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/time';
import BpkTrendDownIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/trend--down';

import type { Icon } from '@skyscanner-internal/falcon-common-types/types/ContentBlockProps';

export default (icon: Icon) => {
  let IconComponent = null;
  switch (icon) {
    case 'search':
      IconComponent = withRtlSupport(BpkSearchIcon);
      break;
    case 'flight':
      IconComponent = withRtlSupport(BpkFlightIcon);
      break;
    case 'money':
      IconComponent = withRtlSupport(BpkMoneyIcon);
      break;
    case 'flight-flexible':
      IconComponent = withRtlSupport(BpkFlightFlexibleIcon);
      break;
    case 'alert--active':
      IconComponent = withRtlSupport(BpkAlertActiveIcon);
      break;
    case 'room':
      IconComponent = withRtlSupport(BpkRoomIcon);
      break;
    case 'insurance':
      IconComponent = withRtlSupport(BpkInsuranceIcon);
      break;
    case 'filter':
      IconComponent = withRtlSupport(BpkFilterIcon);
      break;
    case 'airports':
      IconComponent = withRtlSupport(BpkAirportsIcon);
      break;
    case 'airline':
      IconComponent = withRtlSupport(BpkAirlineIcon);
      break;
    case 'time':
      IconComponent = withRtlSupport(BpkTimeIcon);
      break;
    case 'cleaning-medical':
      IconComponent = withRtlSupport(BpkCleaningMedicalIcon);
      break;
    case 'calendar':
      IconComponent = withRtlSupport(BpkCalendarIcon);
      break;
    case 'flight-landing':
      IconComponent = withRtlSupport(BpkFlightLandingIcon);
      break;
    case 'information':
      IconComponent = withRtlSupport(BpkInformationIcon);
      break;
    case 'refresh':
      IconComponent = withRtlSupport(BpkRefreshIcon);
      break;
    case 'tick-circle':
      IconComponent = withRtlSupport(BpkTickCircleIcon);
      break;
    case 'cars-flexible':
      IconComponent = withRtlSupport(BpkFlexibleIcon);
      break;
    case 'cars':
      IconComponent = withRtlSupport(BpkCarsIcon);
      break;
    case 'petrol':
      IconComponent = withRtlSupport(BpkPetrolIcon);
      break;
    case 'camper-van':
      IconComponent = withRtlSupport(BpkCamperVanIcon);
      break;
    case 'self-service':
      IconComponent = withRtlSupport(BpkSelfServiceIcon);
      break;
    case 'electric':
      IconComponent = withRtlSupport(BpkElectricIcon);
      break;
    case 'location':
      IconComponent = withRtlSupport(BpkLocationIcon);
      break;
    case 'mobile':
      IconComponent = withRtlSupport(BpkMobileIcon);
      break;
    case 'stops':
      IconComponent = withRtlSupport(BpkStopsIcon);
      break;
    case 'trend--down':
      IconComponent = withRtlSupport(BpkTrendDownIcon);
      break;
    default:
      break;
  }
  if (!IconComponent) {
    return null;
  }
  return <IconComponent />;
};
