/* eslint-disable react/no-danger */
import { useEffect } from 'react';
import type { MouseEvent } from 'react';

import loadable from '@loadable/component';
import classnames from 'classnames';
import { redirect } from 'saddlebag-browser';

import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import {
  ACTION_TYPE,
  LOAD_STATUS,
  COMPONENT_NAME,
  COMPONENT_ACTION,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import { anchorScroll } from '../../utils';
import withErrorBoundary from '../ErrorBoundary';

import getIcon from './getIcon';

import type {
  ContentBlockProps,
  ContentBlockSectionItem,
} from '@skyscanner-internal/falcon-common-types/types/ContentBlockProps';

import STYLES from './ContentBlock.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const ContentItem = ({
  contentIncludesHtmlBlockTags,
  description,
  heading,
  icon,
}: ContentBlockSectionItem) => (
  <div key={heading} role="listitem" className={STYLES.ContentBlock__item}>
    {icon && <div className={STYLES.ContentBlock__imgBox}>{getIcon(icon)}</div>}
    <div className={STYLES.ContentBlock__snippet}>
      <BpkText
        tagName="h3"
        textStyle={TEXT_STYLES.label1}
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <div className={STYLES.ContentBlock__itemBody}>
        <BpkText
          // Default p tag
          // Only inline elements can be nested within the P tag, and block elements cannot be nested.
          // Sometimes our description needs to use tags to display the format of the paragraph.
          //  p cannot achieve the display effect very well, so we use div as the tag.
          tagName={contentIncludesHtmlBlockTags ? 'div' : 'p'}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  </div>
);

const ContentColumns = (props: { content: ContentBlockSectionItem[] }) => {
  const middleIndex = Math.ceil(props.content.length / 2);
  const classNames = [
    STYLES.ContentBlock__itemList,
    STYLES.ContentBlock__columns,
  ];
  return (
    <div role="list" className={classnames(classNames)}>
      <div className={STYLES.ContentBlock__column}>
        {props.content.slice(0, middleIndex).map((item) => (
          <ContentItem key={item.heading} {...item} />
        ))}
      </div>
      <div className={STYLES.ContentBlock__column}>
        {props.content.slice(middleIndex).map((item) => (
          <ContentItem key={item.heading} {...item} />
        ))}
      </div>
    </div>
  );
};

const onContentClick = (e: MouseEvent<HTMLDivElement>) => {
  const target = e.target as HTMLAnchorElement;
  if (target?.href) {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.CONTENT_BLOCK,
      component_action: COMPONENT_ACTION.CONTENT_BLOCK.CONTENT_URL_CLICKED,
      link_info: {
        url: target.href,
        content: target.textContent,
      },
    });

    if (target?.hash?.length) {
      anchorScroll(target?.hash.replace('#', ''));
    } else if (target?.target === '_blank') {
      redirect(target?.href, true);
    } else {
      redirect(target?.href);
    }
  }
};

const ContentBlock = (props: ContentBlockProps) => {
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.CONTENT_BLOCK,
      load_status: props?.sections?.length
        ? LOAD_STATUS.LOADED
        : LOAD_STATUS.INVALID,
    });
  }, [props?.sections]);

  if (!props?.cmsTemplate && !props?.sections?.length) {
    return null;
  }

  if (props?.cmsTemplate) {
    return (
      <div
        id="seoContentBlock"
        data-testid="content-block"
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          onContentClick(e);
        }}
        role="presentation"
        className={!props.showInTwoColumns ? STYLES.ContentBlock : ''}
      >
        <div
          className={classnames({
            [STYLES.ContentBlock__contentfulTemplate]: true,
            [STYLES.ContentBlock__contentfulTemplateTwoColumns]:
              props.showInTwoColumns,
          })}
          dangerouslySetInnerHTML={{ __html: props?.cmsTemplate }}
        />
      </div>
    );
  }

  return (
    <div
      id="seoContentBlock"
      data-testid="content-block"
      role="presentation"
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        onContentClick(e);
      }}
      className={
        props.showInTwoColumns
          ? STYLES.ContentBlockTwoColumns
          : STYLES.ContentBlock
      }
    >
      {props?.sections?.map((section) => {
        if (!section?.sectionItems?.length) {
          return null;
        }
        return (
          <div key={section.title} className={STYLES.ContentBlock__container}>
            {section.title && (
              <div className={STYLES.ContentBlock__header}>
                <BpkSectionHeader
                  title={section.title}
                  description={section.subTitle}
                />
              </div>
            )}
            {props.showInTwoColumns && section.sectionItems.length > 1 ? (
              <ContentColumns content={section.sectionItems} />
            ) : (
              <div role="list" className={STYLES.ContentBlock__itemList}>
                {section.sectionItems.map((item) => (
                  <ContentItem key={item.heading} {...item} />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const ContentBlockWrapper = (props: ContentBlockProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.CONTENT_BLOCK,
      })
    }
  >
    <ContentBlock {...props} />
  </IntersectionObserverWrapper>
);

const ContentBlockWithErrorBoundary = withErrorBoundary(
  ContentBlockWrapper,
  'content-block-component',
);

export default ContentBlockWithErrorBoundary;
